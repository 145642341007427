.section-text-image-map {
  &__image {
    @media only screen and (max-width: 767px) {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $small-gap;
    }
  }

  &__map {
    @media only screen and (max-width: 767px) {
      margin-bottom: $small-gap;
    }
  }
}
