.SRLImage {
  max-width: none !important;
  max-height: none !important;
  height: 100% !important;
  width: auto !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  transform: translate(0, 0) !important;
}
