.button {
  width: 25rem;
  height: 7rem;
  line-height: 6.6rem;
  font-size: $button-font-size;
  flex-wrap: wrap;

  &__wrapper {
    display: flex;
    justify-content: center;
    margin-top: $medium-gap;
    margin-bottom: $medium-gap;
  }

  &__button {
    background-color: $primary-color-light;
    color: $white-color;
    transition: background-color 0.2s;
    border: none;

    &:hover {
      background-color: $primary-color;
    }
  }

  &__link {
    border: 2px solid $primary-color;
    align-self: center;
    text-align: center;
    display: inline-block;
    margin: $small-gap;
    transition: all 0.2s;
    &:active,
    &:link,
    &:visited {
      color: $primary-color-dark;
    }

    &:hover {
      color: $white-color;
      background-color: $primary-color;
    }
  }

  &[disabled] {
    background-color: rgba($primary-color-light, 0.4);
  }
}
