.piktogramy-wrapper {
  margin-bottom: $medium-gap;
}

.piktogramy-tooltip {
  width: 12%;
  height: 48%;
  display: inline-block;
  border-radius: 50%;
  position: relative;

  &:not(:nth-child(8n)) {
    margin-right: .5%;
  }

  &--dependance {

    margin-right: 1%;
    &:nth-child(1), &:nth-child(8) {
      margin-left: 6%;
    }
  }

  &--span {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    background-color: $black-color;
    border-radius: 2px;
    padding: .5rem;
    border: 1px solid white;
    color: white;
    z-index: 1;
    text-align: center;
    transform: scale(.8);
   
  }

  &:hover > &--span {
    opacity: 1;   
    width: auto;
    height: auto;
    top: 4rem;
  }
 

  &--wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    
  }

}