.DateRangePicker {
  width: 100%;
  margin: 1rem 0;
  height: 12rem;
}

.DateRangePickerInput {
  width: 100%;
  height: 12rem;
  border: 1px solid $primary-color-dark;
  border-radius: initial;
}

.DateInput_input {
  height: 100%;
  font-weight: 300;
  font-size: $default-font-size;
}

.DateInput_input__focused {
  border-bottom: 3px solid $primary-color-dark;
}

.DateInput {
  width: 100%;
  height: 5.9rem;
}

.DateRangePicker_picker {
  top: 13rem !important;
  transition: all 0.5s;
  @media only screen and (max-width: 320px) {
    left: -1.5rem !important;
  }
}

.DayPicker_transitionContainer {
  transition: all 0.5s;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid $primary-color;
  transition: border 0.2s;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid $primary-color-dark;
}

.DayPickerKeyboardShortcuts_buttonReset {
  &:focus {
    outline: none;
    & > svg {
      fill: #82888a;
    }
  }
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: $primary-color-dark;
  border: 1px solid $primary-color-dark;
}

.CalendarDay__selected_span {
  background-color: $primary-color-light;
  border: 1px solid $primary-color-light;
  &:active,
  &:hover {
    background-color: $primary-color;
    border: 1px double $primary-color;
  }
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background-color: $primary-color-light;
  border: 1px double $primary-color-light;
  color: $primary-color-dark;
}

.DateRangePickerInput_arrow,
.DateRangePickerInput_arrow_svg,
.DateInput_fang {
  width: 0;
  height: 0;
  display: none;
}

.CalendarMonth_caption::first-letter {
  text-transform: uppercase;
}
