.navigation-secondary {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid $primary-color-light;
  margin-bottom: $medium-gap;
  margin-top: $medium-gap;

  &__list {
    list-style-type: none;
    padding-bottom: $medium-gap;
    margin-bottom: 0;
  }
  &__item {
    display: flex;
    justify-content: center;
    margin: 0 $small-gap;
  }
  &__link {
    padding: 0.8rem;
    text-align: center;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.6;
    }
  }
}
