body {
  font-size: $default-font-size;
  color: $black-color;
  line-height: 1.5;
}

.navigation__link {
  font-size: $navigation-font-size;
}

a,
a:active,
a:link,
a:visited {
  text-decoration: none;
  color: $black-color;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary-color-dark;
  font-weight: 500;
  text-align: center;
  margin-bottom: $medium-gap;
}

h1 {
  font-size: 4rem;
  margin-bottom: $medium-gap;
}

h2 {
  font-size: 3.2rem;
}

.heading-2 {
  &__left-small-gap {
    text-align: left;
    margin-bottom: $small-gap;
  }
}

h3 {
  font-size: 2.8rem;
  margin-bottom: $small-gap;
}

// Text
p {
  margin-bottom: $small-gap;
}
