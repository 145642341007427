.header {
  margin-bottom: $medium-gap;
  width: 100%;
  //height: 80vh;
  opacity: 0; // to fix flickering React CSSTransition

  &__picture {
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 20%;
    }
  }
}

.react-header-fade {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }

  &-enter-done {
    opacity: 1; // to fix flickering React CSSTransition
  }
}
