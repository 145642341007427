*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

#root {
  padding-top: $header-navigation-gap;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}
