.error {
  //text-align: center;
  color: $centrum-red;

  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.sent-message {
  text-align: center;
  height: 5rem;
  line-height: 5rem;

  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &--ok {
    background-color: green;
    color: $white-color;
  }

  &--failed {
    background-color: $centrum-red;
    color: $white-color;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
