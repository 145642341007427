// Colors
$centrum-red: rgb(224, 35, 31);
$dependance-blue: rgb(0, 147, 221);
$white-color: rgb(250, 245, 240);
$black-color: rgb(58, 51, 45);
$primary-color: rgb(147, 115, 88);
$primary-color-light: rgb(200, 180, 160);
$primary-color-dark: rgb(96, 78, 67);
// From wordpress site
$table-body-odd: rgba(249, 247, 242, 1);
$table-body-even: rgba(240, 235, 230, 1);

// Typography
$font-spartan: 'Spartan', sans-serif;
$font-raleway: 'Raleway', sans-serif;

$default-font-size: 1.6rem;
$smaller-font-size: 1.4rem;
$navigation-font-size: 1.7rem;
$navigation-font-size-smaller: 1.4rem;
$button-font-size: 2.24rem;

// Layout
$header-navigation-gap: 11.4rem;
$page-width: 114rem;

$very-small-gap: 0.8rem;
$small-gap: 1.6rem;
$medium-gap: 5rem;
$large-gap: 10rem;

$medium-gap-responsive: 3rem;

$room-card-link-margin: 3.6rem;

// Breakpoints
$room-card-bp: 480px;

// Bootstrap breakpoints
$bootstrap-sm: 576px;
$bootstrap-md: 768px;
$bootstrap-lg: 992px;
$bootstrap-xl: 1200px;
