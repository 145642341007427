
.home {
  width: 100%;
  height: 100vh;
  margin-top: -$header-navigation-gap;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1000px) and (orientation: portrait) {
    flex-direction: column;
  }

  &__link {
    width: 30%;
    display: block;
    margin: $medium-gap;
    @media only screen and (max-width: 1280px) {
      width: 40%;
    }
  }

  &__logo {
    display: block;
    width: 100%;
    height: auto;
    filter: grayscale(100);
    transition: filter 0.8s;
  }

  &__piktogramy {
    width: 100%;
    height: auto;
    display: block;
  }

  &__piktogramy--zlate {
    opacity: 1;
    transition: opacity 0.8s;
    position: absolute;
    top: 0;
  }

  &__piktogramy-wrapper {
    position: relative;
    width: 100%;
  }

  

  &__link:hover &__logo {
    filter: grayscale(0);
  }

  &__link:hover &__piktogramy--zlate {
    opacity: 0;
  }
  
}

svg {
  overflow: visible;
}