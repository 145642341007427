.form {
  &__label {
    background-color: $primary-color-light;
    color: $white-color;
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 1rem 0;
    &--date:first-child {
      margin-bottom: 2rem;
    }
  }

  &__text-input {
    width: 100%;
    height: 5rem;
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid $primary-color-dark;
    border-radius: initial;

    &:focus {
      outline: 1px solid $primary-color-dark;
    }
  }

  &__text-area {
    width: 100%;
    height: 10rem;
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid $primary-color-dark;
    border-radius: initial;

    &:focus {
      outline: 1px solid $primary-color-dark;
    }
  }

  &__checkbox-label {
    display: block;
    margin: 1rem;
  }

  &__checkbox-input {
    margin: 1rem;
  }
}
