.section-text-two-columns {
  &__heading {
    text-align: left;
  }

  &__text ul {
    display: inline-block;
    padding-left: 2rem;
  }
}
