.navigation {
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 100;
  min-height: 11.6rem;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.2s;

  &__box--main &__link:first-child {
    margin-left: auto;
    @media only screen and (max-width: 1200px) {
      margin-right: auto;
    }
  }

  &__link {
    margin: 0 1.2rem;
    padding: 0.5rem 0;
    white-space: nowrap;
    box-sizing: content-box;
    transition: opacity 0.2s;
    font-size: $navigation-font-size;
    @media only screen and (min-width: 1200px) {
      //font-size: $navigation-font-size-smaller;
      margin: 0 0.5rem;
    }
    @media only screen and (min-width: 1300px) {
      font-size: $navigation-font-size;
      margin: 0 1.2rem;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &__logo-wrapper &__link {
    opacity: 1;
  }

  &__rezervacia {
    border: 2px solid $primary-color;
    font-size: $navigation-font-size;
    padding: 0.6rem 1rem;
    transition: all 0.2s ease-in-out;
    margin-left: auto;
    margin-right: 2rem;

    @media only screen and (max-width: 1200px) {
      margin-right: auto;
    }

    &:hover {
      border: 2px solid $primary-color;
      background-color: $primary-color;
      color: $white-color;
    }
  }

  &__logo {
    height: 8.8rem;
    padding: 0.5rem 0;
    box-sizing: border-box;
    width: auto;
    transition: transform 0.4s cubic-bezier(1, -1.46, 0.68, 0.66);

    &:hover {
      transform: scale(0.95);
    }
  }

  &__logo-wrapper > a {
    margin-left: 6px;
  }
}

.react-navigation-centrum {
  border-bottom: 2px solid $centrum-red;
}

.react-navigation-dependance {
  border-bottom: 2px solid $dependance-blue;
}
