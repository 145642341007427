.section {
  margin-bottom: $medium-gap;

  &__row--reverse {
    & :nth-child(1) {
      order: 3;
    }
    & :nth-child(2) {
      order: 1;
    }
    & :nth-child(3) {
      order: 2;
    }
  }

  &__map {
    height: 100%;
  }
}

.section-vybavenie {
  & > div.row > div > p {
    display: inline-block;
  }
  & ul {
    width: 50%;
    display: inline-block;
    float: left;
    margin-bottom: $small-gap;
    padding-left: 2rem;
    text-align: left;
  }
}
