.loading {
  &__wrapper {
    display: flex;
    justify-content: center;
    padding: $medium-gap 0;
  }
  &__circle {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    border: 0.5rem solid $primary-color-light;
    border-top: 0.5rem solid $primary-color;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
