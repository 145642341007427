.room-card {
  background-color: $primary-color-light;
  margin: 0 auto $medium-gap auto;

  &__text-container {
    height: 45rem;
  }

  &__text-border {
    height: 35rem;
    margin: $medium-gap;
    border: solid 3px white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $white-color;

    @media only screen and (max-width: $room-card-bp) {
      height: 39rem;
      margin: $medium-gap-responsive;
    }
  }

  &__heading {
    margin: $medium-gap $medium-gap 0 $medium-gap;

    &--link,
    &--link:active,
    &--link:link,
    &--link:visited {
      color: $white-color;
      transition: color 0.2s;
      &:hover {
        color: rgba($white-color, 0.5);
      }
    }
    @media only screen and (max-width: $room-card-bp) {
      font-size: 2.4rem;
      margin: $medium-gap-responsive $medium-gap-responsive 0
        $medium-gap-responsive;
    }
  }

  &__text {
    margin: 0 $medium-gap;
    text-align: center;
    @media only screen and (max-width: $room-card-bp) {
      font-size: $smaller-font-size;
      margin: 0 $medium-gap-responsive;
    }
  }

  &__link,
  &__link:active,
  &__link:link,
  &__link:visited {
    align-self: flex-end;
    margin: 0 $room-card-link-margin $room-card-link-margin 0;
    color: $white-color;
    transition: color 0.2s;

    &:hover {
      color: rgba($white-color, 0.5);
    }
  }

  &__image-container {
    height: 45rem;
    cursor: pointer;
  }

  &__image {
    width: 100%;
    height: 45rem;
    object-fit: cover;
  }
}
