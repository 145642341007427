.line-x {
  height: 2px;
  background-color: $primary-color-light;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $medium-gap;

  &--100 {
    width: 10rem;
  }
}

.collapse-icon-after {
  cursor: pointer;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $primary-color-dark;
    margin-left: 0.8rem;
    vertical-align: 0.5rem;
    display: inline-block;
  }
}

.margin-top-medium-gap {
  margin-top: $medium-gap;
}
