.footer {
  border-top: 2px solid $primary-color-light;

  &__wrapper {
    padding-top: $medium-gap;
  }

  &__box {
    display: flex;
    justify-content: center;
    width: 28rem;
    height: auto;
    padding: 1.5rem;

    &:first-child {
      padding-top: 0;
    }

    @media (max-width: 1200px) {
      text-align: center;
    }
  }

  &__logo {
    display: block;
    max-width: 25rem;
    height: auto;
    align-self: center;
  }

  &__links {
    font-size: $smaller-font-size;
    display: flex;
    justify-content: center;
    padding-bottom: $small-gap;
    & > * {
      margin: $small-gap;
      transition: opacity 0.2s;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
