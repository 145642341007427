.gallery {
  & div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__figure {
    height: 20rem;
    width: 20rem;
    display: block;
    margin: 1rem;
  }
  &__image {
    object-fit: cover;
    width: 20rem;
    height: 20rem;
    vertical-align: middle;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.5;
    }
  }
}
