.hamburger-to-cross {
  &__btn {
    position: absolute;
    width: 10rem;
    height: 10rem;
    border-radius: 10rem;
    border: none;
    outline: none;
    right: 2rem;
    top: 1rem;
    cursor: pointer;
    z-index: 102;
    &:focus {
      outline: none;
    }
  }
  &__btn:hover &__icon,
  &__btn:focus &__icon {
    &::before {
      top: 3.5rem;
    }
    &::after {
      top: 7rem;
    }
  }
  &__icon {
    &,
    &::before,
    &::after {
      width: 5rem;
      height: 0.2rem;
      display: inline-block;
      border-radius: 10rem;
      background-color: black;
      transition: all 0.2s;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 2.5rem;
    }
    &::before {
      top: 4rem;
    }
    &::after {
      top: 6.5rem;
    }
  }
  &__btn:not(.collapsed) > &__icon {
    background-color: transparent;
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 2.5rem;
    }
    &::before {
      top: 5rem;
      transform: rotate(135deg);
    }
    &::after {
      top: 5rem;
      transform: rotate(-135deg);
    }
  }
}
