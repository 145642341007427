.section-denne-menu {
  &__den {
    margin-top: $small-gap;
    margin-bottom: $small-gap;
  }

  &__riadok {
    margin-bottom: $very-small-gap;
    @media only screen and (max-width: $bootstrap-sm) {
      margin-bottom: $small-gap;
    }
  }

  &__den > &__riadok:not(:first-child) > div {
    @media only screen and (max-width: $bootstrap-sm) {
      text-align: center !important;
    }
  }
}
