.cennik-table {
  width: 100%;
  margin-bottom: $small-gap;

  & td,
  & th {
    padding: 1.2rem;
  }

  &__col-1,
  &__col-2 {
    width: 50%;
  }
  &__col-2 {
    text-align: center;
  }

  & td:last-child,
  & th:last-child {
    text-align: center;
  }

  &__head {
    background-color: $primary-color-light;
    color: $white-color;
  }

  &__body {
    color: $black-color;
    & tr:nth-child(2n-1) {
      background-color: $table-body-odd;
    }

    & tr:nth-child(2n) {
      background-color: $table-body-even;
    }
  }
}
